









































































































































































































































































































































.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.infoAll {
  .operationControl {
    border: none;
  }
  .operationControl1 {
    border-top: 1px solid #f5f5f6;
    padding-top: 10px;
  }
}
// .el-table__fixed-body-wrapper {
//   margin-top: -23px;
// }
